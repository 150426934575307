import { graphql, Link } from 'gatsby';
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import Layout from '#/components/layout';

export default function TagsRoute(props) {
  const {
    data: {
      allMarkdownRemark: { group },
    },
  } = props;
  return (
    <Layout>
      <main>
        <h1>Tags</h1>
        <ul>
          {group.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue}
                {' '}
                (
                {tag.totalCount}
                )
              </Link>
            </li>
          ))}
        </ul>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(
      limit: 1000
      filter: {
        fields: {
          type: { in: ["posts", "essays"] }
          draft: { ne: true }
        }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
